window.getScrollTop = function() {
    return window.pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop) || 0
}

window.getOffsetTop = function(element) {
    if (!element) return 0;
    return getOffsetTop(element.offsetParent) + element.offsetTop;
}

window.customRequestAnimationFrame = (function() {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

window.scrollToY = function(o, n) {
    function a() {
        t += 1 / 60;
        var n = t / c, 
            l = i(n);
        1 > n ? (customRequestAnimationFrame(a), window.scrollTo(0, r + (o - r) * l)) : window.scrollTo(0, o)
    }

    var r = window.scrollY,
        o = o || 0, 
        n = n || 2e3, 
        t = 0, 
        c = Math.max(.1, Math.min(Math.abs(r - o) / n, .8)),
        i = function(o) {
            return -.5 * (Math.cos(Math.PI * o) - 1)
        };

    a()
}

window.getJSON = function getJSON(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200)
            callback(null, xhr.response);
        else
            callback(callback, xhr.response);
    }
    xhr.send();
}

window.postJSON = function postJSON(url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('Accept', 'application/json');
    if (document.querySelector('meta[name=csrf-token]'))
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name=csrf-token]').content);
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200)
            callback(null, xhr.response);
        else
            callback(callback, xhr.response);
    }
    xhr.send(JSON.stringify(data));
}

window.putJSON = function putJSON(url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('Accept', 'application/json');
    if (document.querySelector('meta[name=csrf-token]'))
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name=csrf-token]').content);
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200)
            callback(null, xhr.response);
        else
            callback(callback, xhr.response);
    }
    xhr.send(JSON.stringify(data));
}

window.parseQueryString = function parseQueryString(query) {
    var vars = query.length > 0 ? query.split('&') : [];
    var queryString = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        var key = decodeURIComponent(pair[0]);
        //var value = decodeURIComponent(pair[1]);
        var value = decodeURIComponent(pair[1].replace(/\+/g, ' '));

        if (key.indexOf('[]') !== -1)
            key = key.replace('[]', '');

        // If first entry with this name
        if (typeof queryString[key] === 'undefined') {
            queryString[key] = value;
            // If second entry with this name
        } else if (typeof queryString[key] === 'string') {
            var arr = [queryString[key], value];
            queryString[key] = arr;
            // If third or later entry with this name
        } else {
            queryString[key].push(value);
        }
    }

    return queryString;
}

window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}