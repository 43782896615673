/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

require('./static/common');
require('./static/navigation');
require('./static/dropdown');
//require('./static/search');
require('./static/notify');
require('./static/tooltip');
//require('./static/lazyload');
require('./static/gdpr');
require('./static/link-to-top');
//require('./static/subscribe');
//require('./static/unsubscribe');
require('./static/localize');
//require('./static/intro');
//require('./static/recent-articles');
//require('./static/upcoming-events');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//require('./components/Userbar');
//require('./components/Auction');
//require('./components/RecommendedLots');
//require('./components/ViewedLots');