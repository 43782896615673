const localizeModalForm = document.getElementById('localize-modal-form');
const localizeModal = document.getElementById('localize-modal');

//if (typeof user !== 'undefined' && user && user.id && localizeModalForm && localizeModal && typeof window.axios !== 'undefined') {
if (typeof user !== 'undefined' && user && user.id && localizeModalForm && localizeModal && typeof axios !== 'undefined') {

    let localizeModalOpen = true;
    let localizeCompleted = false;

    const lmfCountryIdSelect = localizeModalForm.querySelector('.country-id');
    const lmfTimezoneSelect = localizeModalForm.querySelector('.timezone');
    const lmfLocaleSelect = localizeModalForm.querySelector('.locale');
    const lmfCurrencySelect = localizeModalForm.querySelector('.currency');

    const lmfHeader = localizeModalForm.querySelector('.header');
    const lmfBody = localizeModalForm.querySelector('.body');
    const lmfFooter = localizeModalForm.querySelector('.footer');
    const lmfLoader = localizeModalForm.querySelector('.loader');
    const lmfResult = localizeModalForm.querySelector('.result');

    if (lmfCountryIdSelect) {
        lmfCountryIdSelect.addEventListener('focus', event => {
            if (lmfCountryIdSelect.classList.contains('error')) {
                let lmfCountryIdP = lmfCountryIdSelect.parentNode.querySelector('p');
                if (lmfCountryIdP) {
                    lmfCountryIdP.style.display = 'none';
                    lmfCountryIdP.innerHTML = '';
                }
                lmfCountryIdSelect.classList.remove('error');
            }
        });
    }

    if (lmfTimezoneSelect) {
        lmfTimezoneSelect.addEventListener('focus', event => {
            if (lmfTimezoneSelect.classList.contains('error')) {
                let lmfTimezoneP = lmfTimezoneSelect.parentNode.querySelector('p');
                if (lmfTimezoneP) {
                    lmfTimezoneP.style.display = 'none';
                    lmfTimezoneP.innerHTML = '';
                }
                lmfTimezoneSelect.classList.remove('error');
            }
        });
    }

    if (lmfLocaleSelect) {
        lmfLocaleSelect.addEventListener('focus', event => {
            if (lmfLocaleSelect.classList.contains('error')) {
                let lmfLocalePs = lmfLocaleSelect.parentNode.querySelector('p');
                if (lmfLocaleP) {
                    lmfLocaleP.style.display = 'none';
                    lmfLocaleP.innerHTML = '';
                }
                lmfLocaleSelect.classList.remove('error');
            }
        });
    }

    if (lmfCurrencySelect) {
        lmfCurrencySelect.addEventListener('focus', event => {
            if (lmfCurrencySelect.classList.contains('error')) {
                let lmfCurrencyP = lmfCurrencySelect.parentNode.querySelector('p');
                if (lmfCurrencyP) {
                    lmfCurrencyP.style.display = 'none';
                    lmfCurrencyP.innerHTML = '';
                }
                lmfCurrencySelect.classList.remove('error');
            }
        });
    }


    localizeModalForm.addEventListener('submit', event => {
        event.preventDefault();

        let hasErrors = false;

        if (lmfHeader && lmfBody && lmfFooter && lmfLoader && lmfResult) {

            if (lmfHeader.style.display == 'none')
                lmfHeader.style.display = 'block';
            if (lmfBody.style.display == 'none')
                lmfBody.style.display = 'block';
            if (lmfFooter.style.display == 'none')
                lmfFooter.style.display = 'block';

            if (lmfLoader.style.display !== 'none')
                lmfLoader.style.display = 'none';
            if (lmfResult.style.display !== 'none')
                lmfResult.style.display = 'none';

            let lmfResultMessage = lmfResult.querySelector('.message');
            if (lmfResultMessage)
                lmfResultMessage.innerHTML = '';
        }

        if (lmfCountryIdSelect) {
            let lmfCountryIdP = lmfCountryIdSelect.parentNode.querySelector('p');

            let errorMessage = '';

            if (lmfCountryIdSelect.value == null || lmfCountryIdSelect.value == '')
                errorMessage = 'The Country is required.'

            if (errorMessage.length > 0) {
                hasErrors = true;

                if (lmfCountryIdP) {
                    lmfCountryIdP.style.display = 'block';
                    lmfCountryIdP.innerHTML = errorMessage;
                }
                lmfCountryIdSelect.classList.add('error');
            }
        }

        if (lmfTimezoneSelect) {
            let lmfTimezoneP = lmfTimezoneSelect.parentNode.querySelector('p');

            let errorMessage = '';

            if (lmfTimezoneSelect.value == null || lmfTimezoneSelect.value == '')
                errorMessage = 'The Timezone is required.'

            if (errorMessage.length > 0) {
                hasErrors = true;

                if (lmfTimezoneP) {
                    lmfTimezoneP.style.display = 'block';
                    lmfTimezoneP.innerHTML = errorMessage;
                }
                lmfTimezoneSelect.classList.add('error');
            }
        }

        if (lmfLocaleSelect) {
            let lmfLocaleP = lmfLocaleSelect.parentNode.querySelector('p');

            let errorMessage = '';

            if (lmfLocaleSelect.value == null || lmfLocaleSelect.value == '')
                errorMessage = 'The Locale is required.'

            if (errorMessage.length > 0) {
                hasErrors = true;

                if (lmfLocaleP) {
                    lmfLocaleP.style.display = 'block';
                    lmfLocaleP.innerHTML = errorMessage;
                }
                lmfLocaleSelect.classList.add('error');
            }
        }

        if (!hasErrors) {

            var data = {};
            data['country_id'] = (lmfCountryIdSelect && typeof lmfCountryIdSelect !== 'undefined') ? lmfCountryIdSelect.value : null;
            data['timezone'] = (lmfTimezoneSelect && typeof lmfTimezoneSelect !== 'undefined') ? lmfTimezoneSelect.value : null;
            data['locale'] = (lmfLocaleSelect && typeof lmfLocaleSelect !== 'undefined') ? lmfLocaleSelect.value : null;
            data['currency'] = (lmfCurrencySelect && typeof lmfCurrencySelect !== 'undefined') ? lmfCurrencySelect.value : null;

            if (lmfLoader && lmfLoader.style.display == 'none')
                lmfLoader.style.display = 'flex';

            //window.axios.put('/users/' + user.id + '/localization', data, {headers: {}})
            axios.put('/users/' + user.id + '/localization', data, {headers: {}})
                .then((result) => {
                    let data = result ? result.data : {};

                    if (data && data.data !== null) {
                        /*
                        if (typeof data.message !== 'undefined' && data.message !== null && data.message.length > 0 && lmfResult && typeof lmfResult !== 'undefined') {
                            if (lmfResult.style.display == 'none')
                                lmfResult.style.display = 'block';

                            let lmfResultMessage = lmfResult.querySelector('.message');
                            if (lmfResultMessage)
                                lmfResultMessage.innerHTML = data.message;

                        }
                        */

                        localizeModal.style.display = 'none';
                        localizeModalOpen = false;
                        localizeCompleted = true;

                        if (typeof data.message !== 'undefined' && data.message !== null && data.message.length > 0)
                            notify(data.message, 'success');
                    } else
                        if (typeof data.message !== 'undefined' && data.message)
                            notify(data.message, 'error');
                })
                .catch((error) => {
                    notify((error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message, 'error');
                })
                .finally(() => {
                    if (lmfLoader && lmfLoader.style.display !== 'none')
                        lmfLoader.style.display = 'none';
                });

        }
    });

    const closeButtons = localizeModal.getElementsByClassName('close');
    if (closeButtons) {
        for (let i = 0; i < closeButtons.length; ++i) {
            closeButtons[i].addEventListener('click', event => {
                event.preventDefault();
                localizeModal.style.display = 'none';
                localizeModalOpen = false;
            });
        }
    }
}