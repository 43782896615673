const linkToTop = document.getElementById('link-to-top');
if (linkToTop) {

    linkToTop.addEventListener('click', event => {
        event.preventDefault();
        scrollToY(0, 1500);
    });

    if (getScrollTop() > 250) 
        linkToTop.style.display = 'block';

    window.addEventListener('scroll', function(event) {
        if (getScrollTop() > 250)
            linkToTop.style.display = 'block';
        else
            linkToTop.style.display = 'none';
    });
}