let tooltips = document.getElementsByClassName('tooltip');
if (tooltips && tooltips.length > 0) {
    for (let j = 0; j < tooltips.length; ++j) {
        tooltips[j].addEventListener('mouseover', (event) => {
            //event.preventDefault();
            let target = event.target || event.currentTarget;
            if (target && typeof target.dataset !== 'undefined' && typeof target.dataset.text !== 'undefined') {
                setTimeout(function() {
                    var tip = document.createElement('div');
                    if (target.dataset.text.length >= 200) {
                        tip.setAttribute('class', 'tip tooltip-tip bottom');
                        tip.style.width = '250px';
                    } else if (target.dataset.text.length >= 150) {
                        tip.setAttribute('class', 'tip tooltip-tip bottom');
                        tip.style.width = '200px';
                    } else if (target.dataset.text.length >= 100) {
                        tip.setAttribute('class', 'tip tooltip-tip bottom');
                        tip.style.width = '150px';
                    } else if (target.dataset.text.length >= 20) { 
                        tip.setAttribute('class', 'tip tooltip-tip top');                        
                        tip.style.width = '150px';
                    } else { 
                        tip.setAttribute('class', 'tip tooltip-tip top');                        
                        tip.style.width = '100px';
                        /*
                        tip.style.width = 'auto';
                        tip.style['white-space'] = 'nowrap';
                        */
                    }
                    tip.innerHTML = target.dataset.text;
                    target.append(tip);
                }, 150);
            }
        });
        tooltips[j].addEventListener('mouseout', (event) => {
            //event.preventDefault();
            let target = event.target || event.currentTarget;
            if (target && typeof target.dataset !== 'undefined' && typeof target.dataset.text !== 'undefined') {
                setTimeout(function() {
                    let tips = target.getElementsByClassName('tip');
                    if (tips && tips.length > 0) {
                        for (let l = 0; l < tips.length; ++l)
                            tips[l].remove();
                    }
                }, 250);
            }
        });
    }
}