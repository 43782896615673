const dropdownButtons = document.getElementsByClassName('dropdown-button');
const dropdownContents = document.getElementsByClassName('dropdown-content');
if (dropdownButtons && dropdownContents) {
    for (let j = 0; j < dropdownButtons.length; ++j) {
        dropdownButtons[j].addEventListener('click', event => {
            event.preventDefault();

            let relatedDropdownContents = dropdownButtons[j].parentNode.getElementsByClassName('dropdown-content');
            if (relatedDropdownContents && relatedDropdownContents.length == 1) {
                if (relatedDropdownContents[0].style.display == 'none') {
                    for (let i = 0; i < dropdownContents.length; ++i)
                        if (dropdownContents[i].style.display !== 'none')
                            dropdownContents[i].style.display = 'none';

                    relatedDropdownContents[0].style.display = 'block';
                } else {
                    relatedDropdownContents[0].style.display = 'none';
                }
            }
        });
    }

    window.addEventListener('click', function(event) {

        var target = event.target || event.srcElement;

        const subTags = ['strong', 'span', 'img', 'svg', 'path', 'rect', 'circle', 'g'];

        while (target && target.tagName && subTags.indexOf(target.tagName.toLowerCase()) > -1)
            target = target.parentNode;

        if (target && !target.matches('.dropdown-button') && !target.matches('.dropdown-menu-item')) 
            for (let i = 0; i < dropdownContents.length; ++i)
                if (dropdownContents[i].style.display !== 'none')
                    dropdownContents[i].style.display = 'none';
    });
}