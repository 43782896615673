const navigationToggler = document.getElementById('navigation-toggler');
const navigationContent = document.getElementById('navigation');
if (navigationToggler && navigationContent) {

    if (window.innerWidth >= 768) 
        navigationContent.style.display = 'flex';
    else
        navigationContent.style.display = 'none';

    window.addEventListener('resize', function(event) {
        if (window.innerWidth >= 768) 
            navigationContent.style.display = 'flex';
        else
            navigationContent.style.display = 'none';
    });

    navigationToggler.addEventListener('click', event => {
        event.preventDefault();

        if (navigationContent.style.display == 'none')
            navigationContent.style.display = 'block';
        else
            navigationContent.style.display = 'none';
    });

    window.addEventListener('click', function(event) {
        if (window.innerWidth < 768) {
            var target = event.target || event.srcElement;

            const subTags = ['strong', 'span', 'img', 'svg', 'path', 'rect', 'circle', 'g'];

            while (target && target.tagName && subTags.indexOf(target.tagName.toLowerCase()) > -1)
                target = target.parentNode;

            if (target && !target.matches('.toggler') && !target.matches('.dropdown-button')) 
                if (navigationContent.style.display !== 'none')
                    navigationContent.style.display = 'none';
        }
    });

}