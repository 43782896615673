const gdprModal = document.getElementById('gdpr-modal');
const gdprAcceptButtons = (gdprModal) ? gdprModal.getElementsByTagName('button') : null;

//if (gdprAcceptButtons && typeof window.axios !== 'undefined')
if (gdprAcceptButtons)
    for (let i = 0; i < gdprAcceptButtons.length; ++i)
        gdprAcceptButtons[i].addEventListener('click', event => {
            event.preventDefault();

            //window.axios.post('/users/gdpr', {referrer: window.location.href}, {headers: {}})
            axios.post('/users/gdpr', {referrer: window.location.href}, {headers: {}})
                .then((result) => {
                    let data = (result) ? result.data : {};

                    if (data && data.data !== null)
                        //gdprModal.classList.add('hidden');
                        gdprModal.style.display = 'none';
                    else
                        if (typeof data.message !== 'undefined' && data.message)
                            notify(data.message, 'error');
                })
                .catch((error) => {
                    //console.error(error);
                    //notify(error, 'error');
                    notify((error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message, 'error')
                });

            /*
            postJSON(`${process.env.MIX_ASSET_URL}/api/v1/users/gdpr`, {referrer: window.location.href}, function(error, data) {
                if (error !== null) {
                    //console.error(error);
                    notify(error, 'error');
                } else {
                    if (data && data.data)
                        gdprModal.classList.add('hidden');
                    else
                        if (typeof data.message !== 'undefined' && data.message)
                            notify(data.message, 'error');
                }
            });
            */
        });
