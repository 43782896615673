let notificationContainer = document.querySelector('notification-container');
if (!notificationContainer) {
    notificationContainer = document.createElement('div');
    notificationContainer.classList.add('notification-container');
    document.body.appendChild(notificationContainer);
}

window.notify = function notify(message, type, isHtml) {
    isHtml = isHtml || false;

    let html = '';
    html += '<div class="toast ' + type + '">';
    html += '<i class="icon"></i>';
    if (isHtml)
        html += message;
    else
        html += '<p>' + message + '</p>';
    html += '<button type="button" aria-label="Close"></button>';
    html += '</div>';

    var wrapper = document.createElement('div');
    wrapper.innerHTML = html;

    while (wrapper.firstChild) {
        var notification = wrapper.firstChild;

        notification.addEventListener('click', event => {
            event.preventDefault();
            notification.style.display = 'none';
        });

        setTimeout(function() {
            notification.style.display = 'none';
        }, 5000);

        notificationContainer.appendChild(notification);
    }
}