window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/*
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.baseURL = process.env.MIX_ASSET_URL + '/api/v1';
*/

import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.baseURL = process.env.MIX_ASSET_URL + '/api/v1';

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error && error.response && error.response.status && error.response.status === 401)
        window.location.href = process.env.MIX_ASSET_URL + '/login';

    return Promise.reject(error);
});

window.axios = axios;


/*
const axiosInstance = axios.create(axios.defaults);

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401)
        window.location.href = process.env.MIX_ASSET_URL + '/login';

    return Promise.reject(error);
});

window.axios = axiosInstance;
*/



/*
const _axios = require('axios');

_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
_axios.defaults.headers.common['Accept'] = 'application/json';
_axios.defaults.baseURL = process.env.MIX_ASSET_URL + '/api/v1';

window.axios = _axios.create(_axios.defaults);


window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401)
        window.location.href = process.env.MIX_ASSET_URL + '/login';

    return Promise.reject(error);
});
*/


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


//import "tailwindcss/tailwind.css"